import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { withTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { GatewayContext } from "../../utils/GatewayContext";
import i18n from "../../utils/i18n";
import { groupBy, isEmpty, checkLocalStorage } from "../../utils/Utils";
import queryString from "query-string";
import { ApplicationPaths } from "./../api-authorization/ApiAuthorizationConstants";
import { Container, Box, Grid, InputAdornment, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { SignupForm as useStyles } from "./Styles";
import { Backdrop, RenderHeader, RenderFooter, RenderDialog, Typography, TextField, Select, Checkbox, Switch, ErrorMessage, Button } from "../UI/Core";
import authService from '../api-authorization/AuthorizeService';
import apiRequest from "../../utils/apiRequest";
import clsx from "clsx";

class SignupForm extends Component {
    static displayName = SignupForm.name;

    constructor(props) {
        super(props);
        let params = queryString.parse(window.location.search);
        this.recaptchaRef = React.createRef();
        this.state = {
            token: params.token || "",
            newsletters: [],
            title: "",
            email: "",
            firstName: "",
            lastName: "",
            password: "",
            confirmPassword: "",
            companyName: "",
            jobTitle: "",
            countryCode: "",
            industryCodeParent: "",
            industryCode: "",
            industryParentList: [],
            industryList: [],
            isTermsAccepted: false,
            status: params.status || "",
            reason: params.reason || "",
            returnurl: params.returnurl || "",
            showPassword: false,

            src: params.src || "",
            languageCode: i18n.language,
            errors: {},
            message: {},
            interestIds: [],
            availableTrialSubscription: {},

            RecaptchaResponse: '',
            accountCreated: false,
            disableSubmit: false,
            items: 100,
            isTrial: true
        };
        if (!params.token || !params.status || params.status !== 'success')
            window.location.href = "/invalid-validation" + window.location.search;

        this.checkToken = this.checkToken.bind(this);
        this.getData = this.getData.bind(this);
        this.getUserDetailByToken = this.getUserDetailByToken(this.state.token, this.state.src);
        this.handleSubmit = this.handleSubmit.bind(this);
        // this.handleValidation = this.handleValidation.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.renderGroupedList = this.renderGroupedList.bind(this);
        this.renderParentList = this.renderParentList.bind(this);
        this.renderSubscribeEmail = this.renderSubscribeEmail.bind(this);
        this.handleTrialChange = this.handleTrialChange.bind(this);
        /*
           public class SignupEmailVerification
        {
            public class Result
            {
                public const string Success = "success";
                public const string Failed = "failed";
            }
    
            public class Reason
            {
                public const string Unknown = "unknown";
                public const string Verified = "verified";
                public const string InvalidToken = "invalid_token";
                public const string TokenExpired = "token_expired";
                public const string UserNotExist = "user_not_exist";
                public const string EmailUsed = "email_used";
            }
        }
        */
    }

    async componentDidMount() {
        const isLogined = await authService.isAuthenticated();
        //console.log(this.context);
        const { industryCodes } = this.context;
        const groupedJobTitles = groupBy(industryCodes, "groupName");

        var sorted = groupedJobTitles.sort((a, b) => a.key > b.key ? 1 : a.key === b.key ? 0 : -1)
        // Move 'Other' to the end
        sorted.push(sorted.splice(sorted.indexOf(sorted.find((a => a.key === 'Other'))), 1)[0]);

        this.setState({ industryParentList: sorted });
        if (isLogined)
            window.location.href = "/invalid-validation";

        this.setState({ disableSubmit: true });
        //await this.checkToken();
        await this.getData();
        await this.getNewsletterSubscription();
    }

    async checkToken() {
        let params = queryString.parse(window.location.search);
        try {
            const res = await axios.get("/api/v1/userpendings/tokens/" + params.token);
            if (res.status === 200)
                this.setState({ token: params.token });
        } catch (error) {
            //this.setState({token:params.token,src:params.src});
            window.location.href = "/invalid-validation";
        }
    }

    async getData() {
        try {
            const { items, src } = this.state;
            //const newsletters = await axios.get("/api/v1/newsletter-plans", { params: { limit: this.state.items } });
            const newsletters = await apiRequest.newsletter.listPlans({ Limit: items, Src: src });
            //console.log(newsletters);
            if (newsletters.status === 200) {
                this.setState({ newsletters: newsletters.data.results, disableSubmit: false });
            }
            //this.setState({ newsletters: groups, disableSubmit: false });
        } catch (err) {
            if (err.response.status >= 400 && err.response.status < 500) {
                //console.error(err.response.data);
                this.setState({
                    disableSubmit: false,
                    message: { badRequestError: true }
                });
            } else if (err.response.status === 500) {
                this.setState({
                    disableSubmit: false,
                    message: { internalServerError: true }
                });
            }
        }
    }

    async getNewsletterSubscription() {
        const { items, email } = this.state;
        try {
            let data = checkLocalStorage('subcribed_emails', null, 5, 'minutes');
            if (email) {
                const res = await apiRequest.newsletter.list({ email, Status: 'Active', Limit: items });
                if (res.status === 200) {
                    let interestIds = res.data.results.filter(o => o.status === 'Active').map(o => o.interestId);
                    this.setState({ subNewsletter: res.data.results, interestIds: interestIds, subNewsletterPaging: res.data.paging });
                }
            } else
                this.setState({ subNewsletter: [], interestIds: data || [], subNewsletterPaging: {} });
        } catch (err) {
            if (err.response.status === 400 || err.response.status === 403 || err.response.status === 404) {
                //403: Forbidden 
                //404: No such user
                this.setState({ message: { badRequestError: true } });
            } else if (err.response.status === 500) {
                this.setState({ message: { internalServerError: true } });
            }
        }
    }

    async getUserDetailByToken(token, src) {
        try {
            const res = await apiRequest.userpendings.detailByToken(token, src);
            if (res.status === 200) {
                // console.log(res.data.result);
                this.setState({
                    title: res.data.result.title,
                    firstName: res.data.result.firstName,
                    lastName: res.data.result.lastName,
                    email: res.data.result.email,
                    availableTrialSubscription: res.data.result.trialSubscription
                });
            }
        } catch (err) {
            if (err.response.status === 400 || err.response.status === 403) {
                //403: Forbidden
                console.log(err.response.data.error.message);
                this.setState({
                    message: { badRequestError: true }
                });
            } else if (err.response.status === 500) {
                this.setState({
                    message: { internalServerError: true }
                });
            }
        }
    }

    handleEmailChange = (interestId) => {
        const index = this.state.interestIds.indexOf(interestId);
        if (index > -1) {
            this.state.interestIds.splice(index, 1);
        } else {
            this.state.interestIds.push(interestId);
        }
        this.setState({ interestIds: this.state.interestIds });
    }

    handleTrialChange = (event) => {
        this.setState({ isTrial: event.target.checked }); 
    }

    handleInputChange = (ev) => {
        const name = ev.target.name;
        const value = ev.target.type === "checkbox" ? ev.target.checked : ev.target.value;
        if (name === 'industryCodeParent') {
            if (ev.target.value !== '-') {
                let item = this.state.industryParentList.find((o) => {
                    return (o.key === ev.target.value)
                })
                this.setState({ industryCodeParent: ev.target.value, industryList: item.items, industryCode: item.items[0].code })
            }
        } else {
            if ((name === 'industryCode' || name === 'countryCode') && ev.target.value !== '-') {
                this.setState({
                    ...this.state,
                    [name]: value
                });
            }
            else {
                this.setState({
                    ...this.state,
                    [name]: value
                });
            }
        }
    }


    handleSubmit = async (ev) => {
        if (!(this.state.isTermsAccepted) || (this.state.disableSubmit)) {
            return true;
        }

        ev.preventDefault();
        // if (!this.handleValidation()) {
        try {
            this.recaptchaRef.current.reset();
            const token = await this.recaptchaRef.current.executeAsync();
            this.setState({
                disableSubmit: true,
                errors: {},
                message: {},
                RecaptchaResponse: token
            });
            //console.log(this.state);
            //const signup_resp = await axios.post("/api/v1/users", this.state);
            const signup_resp = await apiRequest.users.submit(this.state);
            //this.recaptchaRef.current.reset();
            if (signup_resp.status === 200) {
                this.setState({ accountCreated: true, disableSubmit: false })
            }
        } catch (err) {
            this.setState({ disableSubmit: false });
            if (err.response.status === 400) {
                const { error } = err.response.data;
                let errors = {};
                if (error.params) {
                    Object.keys(error.params).forEach(function (key) {
                        if (key === "password") {
                            errors[key] = [];
                            error.params[key].forEach(function (item, i) {
                                errors[key][i] = "errors:" + item;
                            })
                        } else if (key === "token") {
                            errors[key] = error.params[key][0];
                        } else {
                            errors[key] = "errors:" + error.params[key][0];
                        }
                    });
                    if (errors.token) {
                        if (errors.token === "token_invalid") {
                            this.setState({
                                errors: errors,
                                message: { tokenExpired: true }
                            });
                        } else if (errors.token === "user_exist") {
                            this.setState({
                                errors: errors,
                                message: { userExist: true }
                            });
                        } else if (errors.token === "email_used_as_work_email") {
                            this.setState({
                                errors: errors,
                                message: { emailUsedAsWorkEmail: true }
                            });
                        }
                    } else {
                        this.setState({ errors: errors });
                    }
                }
            } else if (err.response.status === 500) {
                this.setState({
                    message: { internalServerError: true }
                });
            }
        }
        // }
    }

    handleClickShowPassword = () => {
        const { showPassword } = this.state;
        this.setState({
            ...this.state,
            showPassword: !showPassword
        });
    };

    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    handleClose() {
        this.setState({ message: {} })
    }

    renderGroupedList(list) {
        const { t } = this.props;
        const groupedJobTitles = groupBy(list, "groupName");
        return (
            groupedJobTitles.map((items, key) => {
                return <optgroup label={t("group_name:" + groupedJobTitles[key].key)} key={groupedJobTitles[key].key}>
                    {
                        groupedJobTitles[key].items.map((item) => {
                            return <option key={item.slug} value={item.code}>{t("industries:" + item.code)}</option>
                        })
                    }
                </optgroup>
            })
        )
    }

    renderParentList(list) {
        const { t } = this.props;
        const { industryParentList } = this.state;
        if (!isEmpty(industryParentList)) {
            return (
                industryParentList.map((item) => {
                    return <option key={item.key} value={item.key}>{t("group_name:" + item.key)}</option>
                })
            )
        }
        return (
            <option></option>
        )
    }

    renderSubscribeEmail(list) {
        const { t } = this.props;
        const { interestIds, disableSubmit } = this.state;
        const { classes } = this.props;
        const headerCls = clsx(classes.name, classes.newsletterHeader)
        // console.log(list);

        return (
            list.map((items, i) =>
                <Grid container direction="column" key={"group_" + i} alignItems="center" spacing={3}>
                    <Grid item xs={12} style={{width: '100%'}}>
                        <Typography variant="h5" className={headerCls} style={{width: '100%', paddingBottom: 4, borderBottom: 'solid 1px black'}}>{items.name}</Typography>
                    </Grid>
                    <Grid item xs={11}>
                        {items.plans.map((item, j) =>
                            <Grid container direction="column" key={"item_" + j}>
                                <Grid item xs={12} key={item.interestId}>
                                    <Switch
                                        onChange={() => { this.handleEmailChange(item.interestId) }}
                                        label={<><Typography variant="h6" className={headerCls}>{t("newsletter:" + item.slug)}</Typography><Typography variant="body2" gutterBottom color="textSecondary">{t("newsletter:" + item.slug + "_caption")}</Typography></>}
                                        checked={(interestIds.indexOf(item.interestId) > -1) ? true : false}
                                        name={item.name}
                                        position="start"
                                        fullwidth="true"
                                        className={clsx([classes.switch])}
                                        disabled={disableSubmit}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )
        )
    }

    renderCreated() {
        const { t, classes } = this.props;
        const loginPath = ApplicationPaths.Login;
        const theme = "signup";
        // page data
        const headerData = {
            header: t("sign_up:account_created"),
            theme: theme
        }

        const footerData = [
            <Button component={Link} to={loginPath} fullWidth>{t("common:signin")}</Button>
        ]

        document.title = (headerData.header + " - " + this.props.t("common:haymarket_account")).replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());

        return (
            <Container maxWidth="xs" className={classes.root}>
                <RenderHeader {...headerData} />

                <Box component="section" className={classes.sectionRoot}>
                    <Grid container className={classes.gridContainer}>
                        <Grid item xs>
                            <Typography dangerouslySetInnerHTML={{ __html: t("sign_up:account_created_content") }} variant="body1" component="div" />
                        </Grid>
                    </Grid>
                </Box>

                {footerData && <RenderFooter data={footerData} theme={theme} />}
            </Container>
        )
    }

    renderSignupForm() {
        const { t, classes } = this.props;
        const { errors, message, title, firstName, lastName, email, password, confirmPassword, companyName, industryCodeParent, industryCode, industryList, countryCode, jobTitle, newsletters, isTermsAccepted, disableSubmit, showPassword, src, availableTrialSubscription } = this.state;
        const theme = "signup";

        const RenderSectionHeaderContainer = props => (
            <Grid container component="header" className={classes.sectionHeaderContainer}>
                <Grid item xs>
                    <Typography variant="h5" gutterBottom className={classes.sectionHeader}>{props.header}</Typography>
                </Grid>
            </Grid>
        )

        // page data
        const headerData = {
            header: t("sign_up:sign_up_form"),
            caption: t("sign_up:sign_up_form_caption"),
            theme: theme
        }

        const dialogData = [
            {
                open: message.badRequestError,
                onClose: this.handleClose,
                severity: "error",
                title: t("errors:bad_request_error"),
                children: t("errors:bad_request_error_content")
            },
            {
                open: message.internalServerError,
                onClose: this.handleClose,
                severity: "error",
                title: t("errors:internal_server_error"),
                children: t("errors:internal_server_error_content")
            },
            {
                open: message.tokenExpired,
                onClose: this.handleClose,
                severity: "error",
                title: t("errors:token_invalid"),
                children: t("errors:token_invalid_content")
            },
            {
                open: message.userExist,
                onClose: this.handleClose,
                severity: "error",
                title: t("errors:user_exist"),
                children: t("errors:user_exist_content")
            },
            {
                open: message.emailUsedAsWorkEmail,
                onClose: this.handleClose,
                severity: "error",
                title: t("errors:email_used_as_work_email"),
                children: t("errors:email_used_as_work_email_content")
            }
        ]

        const footerData = [
            <Button fullWidth onClick={this.handleSubmit} disabled={(!(isTermsAccepted) || (disableSubmit))}>{t("common:submit")}</Button>
        ]

        document.title = (headerData.header + " - " + this.props.t("common:haymarket_account")).replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());

        return (
            <Container maxWidth="md" className={classes.root}>
                <Backdrop variant="processing" open={disableSubmit} />
                <RenderHeader {...headerData} />
                <RenderDialog data={dialogData} />

                <Box component="section" className={classes.sectionRoot}>
                    <RenderSectionHeaderContainer header={t("profile:personal_information")} />

                    <Grid container className={classes.gridContainer}>
                        {/* title */}
                        <Grid xs={12} sm={4}>
                            <Select onChange={this.handleInputChange} id="title" name="title" label={t("profile:title") + " *"} value={title} error={Boolean(errors["title"])} disabled={disableSubmit}>
                                <option value=""></option>
                                <GatewayContext.Consumer>
                                    {context => (
                                        context.titles && Object.keys(context.titles).map(key => (
                                            <option key={context.titles[key].slug} value={context.titles[key].slug}>{t("titles:" + context.titles[key].slug)}</option>
                                        ))
                                    )}
                                </GatewayContext.Consumer>
                            </Select>
                            {(errors["title"]) && <ErrorMessage>{t("profile:title")}{t(errors["title"])}</ErrorMessage>}
                        </Grid>

                        {/* first name */}
                        <Grid item xs={12} sm={4}>
                            <TextField value={firstName} onChange={this.handleInputChange} id="firstName" name="firstName" label={t("profile:first_name") + " *"} error={Boolean(errors["firstName"])} disabled={disableSubmit} />
                            {(errors["firstName"]) && <ErrorMessage>{t("profile:first_name")}{t(errors["firstName"])}</ErrorMessage>}
                        </Grid>

                        {/* last name */}
                        <Grid item xs={12} sm={4}>
                            <TextField value={lastName} onChange={this.handleInputChange} id="lastName" name="lastName" label={t("profile:last_name") + " *"} error={Boolean(errors["lastName"])} disabled={disableSubmit} />
                            {(errors["lastName"]) && <ErrorMessage>{t("profile:last_name")}{t(errors["lastName"])}</ErrorMessage>}
                        </Grid>

                        {/* email */}
                        <Grid xs={12}>
                            <TextField value={email} onChange={this.handleInputChange} id="email" name="email" label={t("profile:email") + " *"} error={Boolean(errors["email"])} disabled={true} />
                            {(errors["email"]) && <ErrorMessage>{t("profile:email")}{t(errors["email"])}</ErrorMessage>}
                        </Grid>
                    </Grid>
                </Box>

                {/* password */}
                <Box component="section" className={classes.sectionRoot}>
                    <RenderSectionHeaderContainer header={t("password:password")} />

                    <Grid container className={classes.gridContainer}>
                        <Grid item xs={12} sm={6}>
                            <TextField value={password} onChange={this.handleInputChange} name="password" type={showPassword ? "text" : "password"} InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton onClick={this.handleClickShowPassword} onMouseDown={this.handleMouseDownPassword}> {showPassword ? <Visibility /> : <VisibilityOff />} </IconButton>
                                </InputAdornment>
                            }} label={t("password:password") + " *"} error={Boolean(errors["password"])} disabled={disableSubmit} />
                            {(errors["password"]) && errors["password"].map((item, idx) => <ErrorMessage key={idx}>{t("password:password")}{t(item)}</ErrorMessage>)}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField value={confirmPassword} onChange={this.handleInputChange} name="confirmPassword" type={showPassword ? "text" : "password"} InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton onClick={this.handleClickShowPassword} onMouseDown={this.handleMouseDownPassword}> {showPassword ? <Visibility /> : <VisibilityOff />} </IconButton>
                                </InputAdornment>
                            }} label={t("password:confirm_password") + " *"} error={Boolean(errors["confirmPassword"])} disabled={disableSubmit} />
                            {(errors["confirmPassword"]) && <ErrorMessage>{t("password:password")}{t(errors["confirmPassword"])}</ErrorMessage>}
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="body1" align="left" className={classes.remarks}>{t("password:password_requirement")}</Typography>
                        </Grid>
                    </Grid>
                </Box>

                {/* profile */}
                <Box component="section" className={classes.sectionRoot}>
                    <RenderSectionHeaderContainer header={t("profile:company_information")} />

                    <Grid container className={classes.gridContainer}>
                        <Grid item xs={12}>
                            <TextField value={companyName} onChange={this.handleInputChange} name="companyName" label={t("profile:company_name") + " *"} error={Boolean(errors["companyName"])} disabled={disableSubmit} />
                            {(errors["companyName"]) && <ErrorMessage>{t("profile:company_name")}{t(errors["companyName"])}</ErrorMessage>}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField value={jobTitle} onChange={this.handleInputChange} name="jobTitle" label={t("profile:job_title") + " *"} error={Boolean(errors["jobTitle"])} disabled={disableSubmit} />
                            {(errors["jobTitle"]) && <ErrorMessage>{t("profile:job_title")}{t(errors["jobTitle"])}</ErrorMessage>}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Select value={countryCode} onChange={this.handleInputChange} name="countryCode" label={t("profile:region") + " *"} error={Boolean(errors["countryCode"])} disabled={disableSubmit}>
                                <option>-</option>
                                <GatewayContext.Consumer>
                                    {context => (
                                        context.countries && Object.keys(context.countries).map(key => (
                                            <option key={context.countries[key].code} value={context.countries[key].code}>{t("regions:" + context.countries[key].code)}</option>
                                        ))
                                    )}
                                </GatewayContext.Consumer>
                            </Select>
                            {(errors["countryCode"]) && <ErrorMessage>{t("profile:region")}{t(errors["countryCode"])}</ErrorMessage>}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Select value={industryCodeParent} onChange={this.handleInputChange} name="industryCodeParent" label={t("common:organisation_type") + " *"} error={Boolean(errors["industryCode"])} disabled={disableSubmit}>
                                <option>-</option>
                                <GatewayContext.Consumer>
                                    {context => context.industryCodes && this.renderParentList(context.industryCodes)}
                                </GatewayContext.Consumer>
                            </Select>
                            {(errors["industryCode"]) && <ErrorMessage>{t("common:organisation_category")}{t(errors["industryCode"])}</ErrorMessage>}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Select value={industryCode} onChange={this.handleInputChange} name="industryCode" label={t("profile:organisation_type") + " *"} error={Boolean(errors["industryCode"])} disabled={disableSubmit}>
                                <option>-</option>
                                {!isEmpty(industryList) &&
                                    industryList.sort((a, b) => a.code > b.code ? 1 : a.code === b.code ? 0 : -1).map((item) => {
                                        return <option key={item.slug} value={item.code}>{t("industries:" + item.code)}</option>
                                    })
                                }
                            </Select>
                            {(errors["industryCode"]) && <ErrorMessage>{t("common:organisation_type")}{t(errors["industryCode"])}</ErrorMessage>}
                        </Grid>
                        {/*<Grid item xs={12}>
              <Select value={industryCode} onChange={this.handleInputChange} name="industryCode" label={t("profile:organisation_type") + " *"} error={Boolean(errors["industryCode"])} disabled={disableSubmit}>
                <GatewayContext.Consumer>
                  {context => context.industryCodes && this.renderGroupedList(context.industryCodes)}
                </GatewayContext.Consumer>
              </Select>
              {(errors["industryCode"]) && <ErrorMessage>{t("profile:organisation_type")}{t(errors["industryCode"])}</ErrorMessage>}
            </Grid>*/}
                    </Grid>
                </Box>

                {/* Free Trial */}
                {!isEmpty(availableTrialSubscription) && (
                <Box component="section" className={classes.sectionRoot}>
                    <RenderSectionHeaderContainer
                    header={t("subscription:trial_subscription_heading")}
                    />
                    <Grid
                    container
                    direction="column"
                    key={"group_"}
                    alignItems="center"
                    spacing={3}
                    >
                    <Grid item xs={12} style={{ width: "100%" }}>
                        <Typography variant="body1">
                        {t("subscription:trial_subscription_thanks_message")}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={{ width: "100%", background: "#fff7dd" }}
                    >
                        <Grid container direction="column" key={"item_"}>
                        <Grid item xs={12} key={1}>
                            <Switch
                            onChange={this.handleTrialChange}
                            label={
                                <>
                                <Typography variant="h6">
                                    {availableTrialSubscription.freeTrialPlanName}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    gutterBottom
                                    color="textSecondary"
                                >
                                    {t("subscription:trial_subscription_period")}
                                    {":"} {availableTrialSubscription.freeTrialPeriod}
                                </Typography>
                                </>
                            }
                            checked={this.state.isTrial}
                            name={availableTrialSubscription.freeTrialPlanName}
                            position="start"
                            fullwidth="true"
                            className={clsx([classes.switch])}
                            disabled={disableSubmit}
                            />
                        </Grid>
                        </Grid>
                    </Grid>
                    </Grid>
                </Box>
                )}

                {/* subscribe */}
                <Box component="section" className={classes.sectionRoot}>
                    <RenderSectionHeaderContainer header={t("enewsletter:newsletters")} />

                    {newsletters && this.renderSubscribeEmail(newsletters)}
                </Box>

                {/* tnc */}
                <Box component="section" className={classes.sectionRoot}>
                    {/* <RenderSectionHeaderContainer header={t("terms:terms_conditions")} /> */}

                    <Grid container className={classes.gridContainer}>
                        <Typography variant="body1" align="left" className={classes.remarks} dangerouslySetInnerHTML={{ __html: t("terms:please_see_privacy_terms") }}>
                        </Typography>
                        <Grid item xs>
                            {/* <Box className={classes.textArea} dangerouslySetInnerHTML={{ __html: t("html:terms") }}></Box> */}
                            <Checkbox checked={isTermsAccepted} onChange={this.handleInputChange} name={"isTermsAccepted"} />
                            <span class="MuiFormControlLabel-root" style={{ "cursor": "inherit" }}>
                                <span className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1" dangerouslySetInnerHTML={{ __html: t("terms:accept_tnc") }} />
                            </span>
                            {(errors["isTermsAccepted"]) && <ErrorMessage>{t("terms:terms_conditions")}{t(errors["isTermsAccepted"])}</ErrorMessage>}
                        </Grid>
                    </Grid>
                </Box>

                <RenderFooter data={footerData} theme={theme} />

                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
                    ref={this.recaptchaRef}
                    size="invisible"
                />

            </Container >
        )
    }

    render() {
        const { accountCreated } = this.state;

        if (accountCreated) {
            return this.renderCreated();
        } else {
            return this.renderSignupForm();
        }
    }
}

SignupForm.contextType = GatewayContext;
export default withTranslation(["common", "errors", "sign_up", "profile", "password", "terms", "enewsletter", "newsletter", "regions", "group_names", "industries", "html"])(withStyles(useStyles)(SignupForm));
