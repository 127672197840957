import React, { Component } from "react";
import { Route, Switch, Redirect, withRouter, useLocation } from "react-router-dom";


/* Authorization */
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";
import ApiAuthorizationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";
import { ApplicationPaths } from "./components/api-authorization/ApiAuthorizationConstants";

/* Common */
import Layout from "./components/common/Layout";
import AuthorizeLayout from "./components/common/AuthorizeLayout";
import Privacy from "./components/common/Privacy";
import Terms from "./components/common/Terms";
import Error404 from "./components/common/404";

/* Sign Up */
import Signup from "./components/Signup/Signup";
import SignupForm from "./components/Signup/SignupForm";
import InvalidValidation from "./components/Signup/InvalidValidation";
import EmailValidation from "./components/Signup/EmailValidation";
import ForgotPassword from "./components/Signup/ForgotPassword";
import ResetPassword from "./components/Signup/ResetPassword";
import SendResetPasswordEmail from "./components/Signup/SendResetPasswordEmail";

/* Subscription/Payment */
import SubscriptionSteps from "./components/Payment/SubscriptionSteps";

/* Dashboard */
import Dashboard from "./components/Dashboard/Dashboard";
import Profile from "./components/Dashboard/Profile";
import Addresses from "./components/Dashboard/Addresses";
import Password from "./components/Dashboard/Password";
import SubscribeEmails from "./components/Dashboard/SubscribeEmails";
import Payments from "./components/Dashboard/Payments";
import Subscriptions from "./components/Dashboard/Subscriptions";
import SubscriptionDetail from "./components/Dashboard/SubscriptionDetail";
// import LegacySubscriptions from "./components/Dashboard/LegacySubscriptions";
import Licenses from "./components/Dashboard/Licenses";
import Corporate from "./components/Dashboard/Corporate";
import VerifyWorkEmail from "./components/Signup/VerifyWorkEmail";
import SelectBrand from "./components/Payment/SelectBrand";


import axios from "axios";
import ReactGA from "react-ga4";
import i18n from "./utils/i18n";
import { isEmpty, tokenHeader } from "./utils/Utils";
import apiRequest from "./utils/apiRequest";

import { GatewayContext } from "./utils/GatewayContext";

import { Backdrop } from "./components/UI/Core";
// import links from "./links/links.json";


class App extends Component {
  static displayName = App.name;

  constructor(props) {
    console.info("ver:", `${process.env.REACT_APP_VERSION} (${process.env.REACT_APP_ENVNAME})`);
    super(props);
    this.state = {
      loadedTrans: false,
      metadata: {}
    };
    this.getTranslation = this.getTranslation.bind(this);
  }

  async componentDidMount() {
    const token = await tokenHeader();
    ReactGA.initialize([
      /* {
        trackingId: "your GA measurement id",
        gaOptions: {...}, // optional
        gtagOptions: {...}, // optional
      },
      {
        trackingId: "your second GA measurement id",
      }, */
      {
        trackingId:`${process.env.REACT_APP_GOOGLE_ANALYTICS}`
      }
    ]);
    this.onRouteChanged(this.props.location)
    window.$token = (token.Authorization) ? token.Authorization.replace("Bearer ", "") : ''
    this.getTranslation();
    this.getMetaData();

    // Set recaptcha in Signup.js to global
    // https://www.npmjs.com/package/react-google-recaptcha#global-properties-used-by-recaptcha
    window.recaptchaOptions = {
      useRecaptchaNet: true,
    };
  }

  async componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged(this.props.location)
    }
  }

  async getMetaData() {
    try {
      const cached = JSON.parse(localStorage.getItem("metadata") || "{}");
      if(cached && cached.version === process.env.REACT_APP_VERSION) {
        // console.debug('cached', cached.result)
        this.setState({ metadata: cached.result })
      } else {
        const res = await apiRequest.metadata.list("metadata");
        // res.data.result.links = links;
        // console.debug('fetched', res.data.result)
        localStorage.setItem("metadata", JSON.stringify({
          version: process.env.REACT_APP_VERSION,
          result: res.data.result
        }))
        this.setState({ metadata: res.data.result })
      }
      
    } catch (err) {
      console.error("App getMetaData() error", err);
    }
  }

  async importAppResources(languages) {
    let resx = {};
    let promises = languages.map(async(lang) =>{
      let kvp = {}
      kvp[lang] = await import(`../src/langs/app.${ lang }`);
      return kvp;
    })
    await Promise.all(promises).then((responses) => {
      responses.forEach(res => {
        let keyname = Object.keys(res)[0];
        resx[keyname] = res[keyname].default
      })
    })
    // console.debug(resx)
    return resx;
  }

  async getResources() {
    let resx = {};
    try {
      const languages = (process.env.REACT_APP_LANGUAGES).split(",").map(l => l.trim());
      if (process.env.REACT_APP_ENVNAME !== 'development') {
        resx = await this.importAppResources(languages);
      } else {
        let promises = languages.map(lang => axios.get(`/api/v1/locales/${lang}`));
        await Promise.all(promises).then((responses) => {
          responses.forEach(res => {
            let { data } = res;
            if (data && data.result) {
              let keyname = Object.keys(res.data.result)[0];
              resx[keyname] = res.data.result[keyname];
            }
          })
        });
      }

      let promises = languages.map(lang => axios.get(`/api/v1/locales/${lang}?DocumentPath=html`));
      await Promise.all(promises).then((responses) => {
        responses.forEach(res => {
          let { data } = res;
          // console.log(data);
          if (data && data.result) {
            let keyname = Object.keys(res.data.result)[0];
            resx[keyname] = {...resx[keyname],...res.data.result[keyname]};
          }
        })
      });
      // console.log(resx);
      return resx;
    }catch (err) {
      console.error("App getResources() error", err);
    }
  }

  async getTranslation() {
    try {
      let langs = await this.getResources();

      for (let [lang, v] of Object.entries(langs)) {
        if(v){
          for (let [ns, obj] of Object.entries(v)) {
            i18n.addResourceBundle(lang, ns, obj, true, true);
          }
        }
      }
      this.setState({ loadedTrans: true });

    } catch (err) {
      console.error("App getTranslation() error", err);
    }
  }

  onRouteChanged(location) {
    ReactGA.send({ 
      hitType: "pageview", 
      page: `${location.pathname}${location.search}` 
    });
  }

  render() {
    const { loadedTrans, metadata } = this.state;
    const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
      <Route {...rest} render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )} />
    )

    return (loadedTrans && !isEmpty(metadata)) ? (
      <GatewayContext.Provider value={this.state.metadata}>
        <Switch>
          <AppRoute strict path="/signup/finish" layout={Layout} component={SignupForm} />
          <AppRoute exact strict path="/signup" layout={Layout} component={Signup} />
          <AppRoute exact path="/invalid-validation" layout={Layout} component={InvalidValidation} />
          <AppRoute exact path="/email-validation" layout={Layout} component={EmailValidation} />
          <AppRoute path="/email-validation/:email" layout={Layout} component={EmailValidation} />
          <AppRoute exact path="/forgot-password" layout={Layout} component={ForgotPassword} />
          <AppRoute exact path="/reset-password" layout={Layout} component={ResetPassword} />
          <AppRoute path="/reset-password-email/:email" layout={Layout} component={SendResetPasswordEmail} />
          <AppRoute exact path="/reset-password-email" layout={Layout} component={SendResetPasswordEmail} />
          <AppRoute exact path="/work-email/verify" layout={Layout} component={VerifyWorkEmail} />
          <AuthorizeRoute path="/subscription/:step" layout={AuthorizeLayout} component={SubscriptionSteps} />
          <Route path={ApplicationPaths.ApiAuthorizationPrefix} layout={Layout} component={ApiAuthorizationRoutes} />
          <AuthorizeRoute exact path="/manage" layout={AuthorizeLayout} component={Dashboard} />
          <AuthorizeRoute exact path="/manage/profile" layout={AuthorizeLayout} component={Profile} />
          <AuthorizeRoute exact path="/manage/profile/edit/:type" layout={AuthorizeLayout} component={Profile} />
          <AuthorizeRoute path="/manage/addresses/" layout={AuthorizeLayout} component={Addresses} />
          <AuthorizeRoute path="/manage/addresses/edit" layout={AuthorizeLayout} component={Addresses} />
          <AuthorizeRoute exact path="/manage/password" layout={AuthorizeLayout} component={Password} />
          <AuthorizeRoute exact path="/manage/subscribe-emails" layout={AuthorizeLayout} component={SubscribeEmails} />
          <AuthorizeRoute exact path="/manage/payment-method" layout={AuthorizeLayout} component={Payments} />
          <AuthorizeRoute exact path="/manage/payment-method/add-payment" layout={AuthorizeLayout} component={Payments} />
          {/* <AuthorizeRoute exact path="/manage/subscriptions/legacy" layout={AuthorizeLayout} component={LegacySubscriptions} /> */}
          <AuthorizeRoute exact path="/manage/subscriptions/:stripeSubscriptionId" layout={AuthorizeLayout} component={SubscriptionDetail} />
          <AuthorizeRoute exact path="/manage/subscriptions/change-payment/:stripeSubscriptionId" layout={AuthorizeLayout} component={SubscriptionDetail} />
          <AuthorizeRoute exact path="/manage/subscriptions/pay/:stripeSubscriptionId" layout={AuthorizeLayout} component={SubscriptionDetail} />
          <AuthorizeRoute exact path="/manage/subscriptions/paydue/:stripeSubscriptionId" layout={AuthorizeLayout} component={SubscriptionDetail} />
          <AuthorizeRoute exact path="/manage/subscriptions/paydue/change-payment/:stripeSubscriptionId" layout={AuthorizeLayout} component={SubscriptionDetail} />
          <AuthorizeRoute exact path="/manage/subscriptions/paydue/add-payment/:stripeSubscriptionId" layout={AuthorizeLayout} component={SubscriptionDetail} />
          <AuthorizeRoute exact path="/manage/subscriptions/paydue/complete/:stripeSubscriptionId" layout={AuthorizeLayout} component={SubscriptionDetail} />
          <AuthorizeRoute exact path="/manage/subscriptions" layout={AuthorizeLayout} component={Subscriptions} />
{/*          <AuthorizeRoute exact path="/manage/subscription-admin" layout={AuthorizeLayout} component={BulkSubscription} />
          <AuthorizeRoute exact path="/manage/subscription-admin/:stripeSubscriptionId" layout={AuthorizeLayout} component={SubscriptionDetail} />
*/}       <AuthorizeRoute exact path="/manage/corporate" layout={AuthorizeLayout} component={Corporate} />
          <AuthorizeRoute exact path="/manage/subscription/trial" layout={AuthorizeLayout} component={SelectBrand} />
          <AuthorizeRoute exact path="/manage/corporate/:stripeSubscriptionId" layout={AuthorizeLayout} component={SubscriptionDetail} />
          <AuthorizeRoute exact path="/manage/licenses/:stripeSubscriptionId" layout={AuthorizeLayout} component={Licenses} />
          <AuthorizeRoute exact path="/manage/add-tenants/:stripeSubscriptionId" layout={AuthorizeLayout} component={Licenses} />
          <AuthorizeRoute exact path="/manage/delete-tenants/:stripeSubscriptionId" layout={AuthorizeLayout} component={Licenses} />
          <AppRoute exact path="/privacy" layout={Layout} component={Privacy} />
          <AppRoute exact path="/terms" layout={Layout} component={Terms} />
          <AppRoute exact path="/404" layout={Layout} component={Error404} />
          <Route exact path="/" render={() => { return (<Redirect to="/manage" />)}} />
          <Redirect to="/404" />
        </Switch>
      </GatewayContext.Provider>
    ) : <Backdrop />

    
  }
}


export default withRouter(App)