import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { Box, Grid, Container } from "@material-ui/core";
import { Checkout as useStyles } from "./Styles";
import {
  RenderSection,
  Button,
  RenderDialog,
  Typography,
  ErrorMessage,
  RenderHeader,
} from "../UI/Core";
import apiRequest from "../../utils/apiRequest";
import { tokenHeader, isEmpty } from "../../utils/Utils";
import { Redirect } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import clsx from "clsx";

class SelectBrand extends Component {
  static displayName = SelectBrand.name;
  constructor(props) {
    super(props);
    this.state = {
      parentPath: "/manage",
      message: {},
      token: "",
      availableTrialSubscriptions: [],
      brandStatus: false,
      redirectPage: false,
      errors: [],
      submitConfirm: false
    };

    this.handleClose = this.handleClose.bind(this);
    this.getBrands = this.getBrands.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleConfirmSubmit = this.handleConfirmSubmit.bind(this);
  }

  async componentDidMount() {
    await this.getBrands();
    this.setState({ token: await tokenHeader() });
  }

  handleOptionChange = async (event) => {
    let planId = event.target.value;
    this.setState({ planId });
  };

  handleClose() {
    const { message } = this.state;
    this.setState({
      message: {},
      redirectPage: message?.active ? true : false,
    });
  }

  handleSelect(planId) {
    this.setState({ planId: planId, submitConfirm: true, message: {submitActive: true} });
  }

  async getBrands() {
    try {
      const res = await apiRequest.brands.list();
      if (res.status === 200) {
        this.setState({ availableTrialSubscriptions: res.data.results });
      }
    } catch (err) {
      if (err.response.status === 400 || err.response.status === 403) {
        this.setState({
          message: { badRequestError: true },
        });
      } else if (err.response.status === 500) {
        this.setState({
          message: { internalServerError: true },
        });
      }
    }
  }

  handleConfirmSubmit = async () => {
    this.setState({message: {submitActive: false}})
    const brandStatus = await apiRequest.brands
      .submit({
        StripePlanId: this.state.planId,
      })
      .catch((err) => {
        return err.response;
      });

    if (brandStatus?.status === 200) {
      this.setState({
        message: { active: true },
        brandStatus: true,
      });
    } else if (brandStatus?.status === 409) {
      this.setState({
        message: { alreadyActive: true },
        brandStatus: true,
      });
    }
  };

  render() {
    const { message, availableTrialSubscriptions, errors, submitConfirm } = this.state;

    const { classes, t } = this.props;

    const messageData = [
      {
        open: message.active,
        onClose: this.handleClose,
        severity: "success",
        title: t("subscription:trial_subscription_activated"),
      },
      {
        open: message.badRequestError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:bad_request_error"),
        children: t("errors:bad_request_error_content"),
      },
      {
        open: message.internalServerError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:internal_server_error"),
        children: t("errors:internal_server_error_content"),
      },
      {
        open: message.alreadyActive,
        onClose: this.handleClose,
        severity: "warning",
        title: t("subscription:trial_subscription_already_used"),
      },
    ];

    const dialogData = [
      {
        open: message.submitActive,
        onClose: this.handleClose,
        onConfirm: this.handleConfirmSubmit,
        children: t("subscription:trial_subscription_confirm")
      }
    ]

    const headerData = {
      header: t("subscription:trial_subscription_heading"),
      caption: t("subscription:trial_subscription_message"),
    };

    const remarkData = [
      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{
          __html: t("payment:subscription_terms", {
            link: "/terms",
            className: classes.link,
          }),
        }}
        className={classes.remark}
      />,
    ];

    const selectPlanText = t("subscription:trial_subscription_select_plan");

    return (
      <Container maxWidth="md" className={classes.root}>
        <RenderHeader {...headerData} />
        {!isEmpty(availableTrialSubscriptions) ? (
          <>
            {availableTrialSubscriptions.map((item, i) => (
              <Box key={i} component="section" className={classes.sectionRoot}>
                {i === 0 && (
                  <Grid
                    container
                    className={classes.gridContainer}
                    alignItems="flex-end"
                  >
                    <Grid item xs={7} sm={8} md={9}>
                      <Typography variant="body1" className={classes.title}>
                        {selectPlanText}
                      </Typography>
                    </Grid>
                    <Grid item xs={5} sm={4} md={3}>
                      <Typography
                        variant="body1"
                        className={clsx(classes.title, classes.end)}
                      >
                        {t("payment:price")}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                <RenderbrandButton
                  {...this.props}
                  item={item}
                  key={item.freeTrialPlanName}
                  planId={item.stripePlanId}
                  onClick={this.handleSelect}
                />
              </Box>
            ))}
            {errors["subscription"] && (
              <ErrorMessage>
                {t("common:subscription") + t("errors:is_required")}
              </ErrorMessage>
            )}
          </>
        ) : (
          <Alert severity="info" icon={false}>
            {t("subscription:no_valid_subscription_select")}
          </Alert>
        )}

        {this.state.brandStatus && <RenderDialog data={messageData} />}
        {submitConfirm && <RenderDialog data={dialogData} />}
        {this.state.redirectPage && <Redirect to="/manage" />}
        <RenderSection data={remarkData} />
      </Container>
    );
  }
}

class RenderbrandButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planId: this.props.planId,
      checkDuplicate: false,
    };
  }

  render() {
    const { t, classes, item, onClick, isDisabled } = this.props;
    const selected = this.state.planId === this.props.item.stripePlanId;

    return (
      <Button
        color="secondary"
        fullWidth
        onClick={() => onClick(this.props.item.stripePlanId)}
        className={clsx(classes.button, selected && classes.highlight)}
        disabled={isDisabled}
      >
        <Grid container className={classes.gridContainer}>
          <Grid item xs={7} sm={8} md={9} className={classes.item}>
            <Typography variant="h6" className={classes.body} style={{textAlign: 'left'}}>
              {item.freeTrialPlanName}
            </Typography>
            <Typography variant="body1" style={{textAlign: 'left'}}>
              {t("subscription:trial_subscription_period")}
              {":"} {item.freeTrialPeriod}
            </Typography>
            <Typography variant="body1" className={classes.remark} style={{textAlign: 'left'}}>
              {t("subscription:trial_subscription_auto_renew")}{" "}
              <b>{item.stripePlanName}</b>
            </Typography>
          </Grid>
          <Grid item xs={5} sm={4} md={3} className={classes.item}>
            <Typography
              variant="h6"
              className={clsx(classes.body, classes.end, classes.currency)}
            >
              {"HKD"} {0}
            </Typography>
          </Grid>
        </Grid>
      </Button>
    );
  }
}

export default withTranslation(["common", "subscriptions", "errors"])(
  withStyles(useStyles)(SelectBrand)
);
